import { default as aboutIYttGxXltYMeta } from "/workspace/pages/about.vue?macro=true";
import { default as _91id_934xsZMTJuZeMeta } from "/workspace/pages/apps/[id].vue?macro=true";
import { default as indexXTf4eLkcf1Meta } from "/workspace/packages/auth/pages/auth/forgot-password/index.vue?macro=true";
import { default as new_45passwordTJE0JHjZWqMeta } from "/workspace/packages/auth/pages/auth/forgot-password/new-password.vue?macro=true";
import { default as indexlcxupfF2QMMeta } from "/workspace/packages/auth/pages/auth/index.vue?macro=true";
import { default as devKUKwvxrX5rMeta } from "/workspace/packages/auth/pages/auth/invitation/dev.vue?macro=true";
import { default as prokORVi65AXEMeta } from "/workspace/packages/auth/pages/auth/invitation/pro.vue?macro=true";
import { default as dev0kb9wrCHn0Meta } from "/workspace/packages/auth/pages/auth/login/dev.vue?macro=true";
import { default as proXMqHgZZMd9Meta } from "/workspace/packages/auth/pages/auth/login/pro.vue?macro=true";
import { default as oauth858D4dwL5HMeta } from "/workspace/packages/auth/pages/auth/oauth.vue?macro=true";
import { default as password_45lessGecIuzjLMFMeta } from "/workspace/packages/auth/pages/auth/password-less.vue?macro=true";
import { default as devlktZQSRmVRMeta } from "/workspace/packages/auth/pages/auth/signup/dev.vue?macro=true";
import { default as devNYk7wMTSA5Meta } from "/workspace/packages/auth/pages/auth/verification/dev.vue?macro=true";
import { default as competitionTad8X9v6L3Meta } from "/workspace/pages/competition.vue?macro=true";
import { default as all_45inclusivePHdUacs3X9Meta } from "/workspace/pages/dev/all-inclusive.vue?macro=true";
import { default as contactcv06fcFw7pMeta } from "/workspace/pages/dev/contact.vue?macro=true";
import { default as indexxaPbEO2OZpMeta } from "/workspace/pages/dev/index.vue?macro=true";
import { default as iso_4582304_452_45assessment5QtE3dkuQBMeta } from "/workspace/pages/dev/iso-82304-2-assessment.vue?macro=true";
import { default as pre_45clinical_45assessment2DSj2mjwvyMeta } from "/workspace/pages/dev/pre-clinical-assessment.vue?macro=true";
import { default as trust_45report1xr2pFsE0rMeta } from "/workspace/pages/dev/trust-report.vue?macro=true";
import { default as all_45inclusiveYf2guohUSZMeta } from "/workspace/packages/developer/pages/developer/assessment/all-inclusive.vue?macro=true";
import { default as iso_4582304_452DmeQcwML4HMeta } from "/workspace/packages/developer/pages/developer/assessment/iso-82304-2.vue?macro=true";
import { default as pre_45clinicalozHhrHp7i4Meta } from "/workspace/packages/developer/pages/developer/assessment/pre-clinical.vue?macro=true";
import { default as business_45model8BjPOxII9pMeta } from "/workspace/packages/developer/pages/developer/business-model.vue?macro=true";
import { default as claim4F7Y2E0dC1Meta } from "/workspace/packages/developer/pages/developer/claim.vue?macro=true";
import { default as first_45claimsiWzBnylp1Meta } from "/workspace/packages/developer/pages/developer/first-claim.vue?macro=true";
import { default as indexVdgCBC7u65Meta } from "/workspace/packages/developer/pages/developer/index.vue?macro=true";
import { default as profileJ5ccJHz4nTMeta } from "/workspace/packages/developer/pages/developer/profile.vue?macro=true";
import { default as trust_45reportdhxisW7BnJMeta } from "/workspace/packages/developer/pages/developer/trust-report.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta } from "/workspace/pages/investors/questions-to-de-risk-your-next-digital-health-investment.vue?macro=true";
import { default as privacy_45policyJuUuuPjb3dMeta } from "/workspace/pages/privacy-policy.vue?macro=true";
import { default as indexiZvgslzpztMeta } from "/workspace/pages/pro/index.vue?macro=true";
import { default as _91libraryId_93cSSZuaDSOtMeta } from "/workspace/pages/pro/libraries/[libraryId].vue?macro=true";
import { default as get_45libraryqC6iwwjphhMeta } from "/workspace/pages/pro/libraries/get-library.vue?macro=true";
import { default as indexIWUrDsg8fTMeta } from "/workspace/pages/pro/libraries/index.vue?macro=true";
import { default as quality_45scoresuTAPXP0d1KMeta } from "/workspace/pages/quality-scores.vue?macro=true";
import { default as relevanceSh4qMazwVSMeta } from "/workspace/pages/relevance.vue?macro=true";
import { default as request_45reviewVUAHAzK7TRMeta } from "/workspace/pages/request-review.vue?macro=true";
import { default as _91slug_93VlgNx52LwyMeta } from "/workspace/pages/resources/articles/[slug].vue?macro=true";
import { default as _91condition_93NifOgurSaXMeta } from "/workspace/pages/resources/articles/conditions/[condition].vue?macro=true";
import { default as indexE83Z3kIzhYMeta } from "/workspace/pages/resources/articles/index.vue?macro=true";
import { default as _91condition_93NFNcrWrvLYMeta } from "/workspace/pages/resources/collections/medication-adherence/[condition].vue?macro=true";
import { default as index2qEdVOhH2PMeta } from "/workspace/pages/resources/collections/medication-adherence/index.vue?macro=true";
import { default as _91condition_93neppnJFc0BMeta } from "/workspace/pages/resources/collections/quit-smoking/[condition].vue?macro=true";
import { default as indexsnk7Ndn6r9Meta } from "/workspace/pages/resources/collections/quit-smoking/index.vue?macro=true";
import { default as terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta } from "/workspace/pages/terms-and-conditions-2021-10-25.vue?macro=true";
import { default as terms_45and_45conditionsD3pGvh9rLRMeta } from "/workspace/pages/terms-and-conditions.vue?macro=true";
import { default as _91_46_46_46code_930MHcggYpvxMeta } from "/workspace/pages/u/[...code].vue?macro=true";
import { default as _91locale_93_45_91id_935Pvkxs9hVcMeta } from "/workspace/pages/u/redirect/[locale]-[id].vue?macro=true";
export default [
  {
    name: aboutIYttGxXltYMeta?.name ?? "about___en",
    path: aboutIYttGxXltYMeta?.path ?? "/about",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutIYttGxXltYMeta?.name ?? "about___fr",
    path: aboutIYttGxXltYMeta?.path ?? "/fr/about",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_934xsZMTJuZeMeta?.name ?? "apps-id___en",
    path: _91id_934xsZMTJuZeMeta?.path ?? "/apps/:id()",
    meta: _91id_934xsZMTJuZeMeta || {},
    alias: _91id_934xsZMTJuZeMeta?.alias || [],
    redirect: _91id_934xsZMTJuZeMeta?.redirect,
    component: () => import("/workspace/pages/apps/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934xsZMTJuZeMeta?.name ?? "apps-id___fr",
    path: _91id_934xsZMTJuZeMeta?.path ?? "/fr/apps/:id()",
    meta: _91id_934xsZMTJuZeMeta || {},
    alias: _91id_934xsZMTJuZeMeta?.alias || [],
    redirect: _91id_934xsZMTJuZeMeta?.redirect,
    component: () => import("/workspace/pages/apps/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXTf4eLkcf1Meta?.name ?? "auth-forgot-password___en",
    path: indexXTf4eLkcf1Meta?.path ?? "/auth/forgot-password",
    meta: indexXTf4eLkcf1Meta || {},
    alias: indexXTf4eLkcf1Meta?.alias || [],
    redirect: indexXTf4eLkcf1Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexXTf4eLkcf1Meta?.name ?? "auth-forgot-password___fr",
    path: indexXTf4eLkcf1Meta?.path ?? "/fr/auth/forgot-password",
    meta: indexXTf4eLkcf1Meta || {},
    alias: indexXTf4eLkcf1Meta?.alias || [],
    redirect: indexXTf4eLkcf1Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordTJE0JHjZWqMeta?.name ?? "auth-forgot-password-new-password___en",
    path: new_45passwordTJE0JHjZWqMeta?.path ?? "/auth/forgot-password/new-password",
    meta: new_45passwordTJE0JHjZWqMeta || {},
    alias: new_45passwordTJE0JHjZWqMeta?.alias || [],
    redirect: new_45passwordTJE0JHjZWqMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/forgot-password/new-password.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordTJE0JHjZWqMeta?.name ?? "auth-forgot-password-new-password___fr",
    path: new_45passwordTJE0JHjZWqMeta?.path ?? "/fr/auth/forgot-password/new-password",
    meta: new_45passwordTJE0JHjZWqMeta || {},
    alias: new_45passwordTJE0JHjZWqMeta?.alias || [],
    redirect: new_45passwordTJE0JHjZWqMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/forgot-password/new-password.vue").then(m => m.default || m)
  },
  {
    name: indexlcxupfF2QMMeta?.name ?? "auth___en",
    path: indexlcxupfF2QMMeta?.path ?? "/auth",
    meta: indexlcxupfF2QMMeta || {},
    alias: indexlcxupfF2QMMeta?.alias || [],
    redirect: indexlcxupfF2QMMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexlcxupfF2QMMeta?.name ?? "auth___fr",
    path: indexlcxupfF2QMMeta?.path ?? "/fr/auth",
    meta: indexlcxupfF2QMMeta || {},
    alias: indexlcxupfF2QMMeta?.alias || [],
    redirect: indexlcxupfF2QMMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: devKUKwvxrX5rMeta?.name ?? "auth-invitation-dev___en",
    path: devKUKwvxrX5rMeta?.path ?? "/auth/invitation/dev",
    meta: devKUKwvxrX5rMeta || {},
    alias: devKUKwvxrX5rMeta?.alias || [],
    redirect: devKUKwvxrX5rMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/invitation/dev.vue").then(m => m.default || m)
  },
  {
    name: devKUKwvxrX5rMeta?.name ?? "auth-invitation-dev___fr",
    path: devKUKwvxrX5rMeta?.path ?? "/fr/auth/invitation/dev",
    meta: devKUKwvxrX5rMeta || {},
    alias: devKUKwvxrX5rMeta?.alias || [],
    redirect: devKUKwvxrX5rMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/invitation/dev.vue").then(m => m.default || m)
  },
  {
    name: prokORVi65AXEMeta?.name ?? "auth-invitation-pro___en",
    path: prokORVi65AXEMeta?.path ?? "/auth/invitation/pro",
    meta: prokORVi65AXEMeta || {},
    alias: prokORVi65AXEMeta?.alias || [],
    redirect: prokORVi65AXEMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/invitation/pro.vue").then(m => m.default || m)
  },
  {
    name: prokORVi65AXEMeta?.name ?? "auth-invitation-pro___fr",
    path: prokORVi65AXEMeta?.path ?? "/fr/auth/invitation/pro",
    meta: prokORVi65AXEMeta || {},
    alias: prokORVi65AXEMeta?.alias || [],
    redirect: prokORVi65AXEMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/invitation/pro.vue").then(m => m.default || m)
  },
  {
    name: dev0kb9wrCHn0Meta?.name ?? "auth-login-dev___en",
    path: dev0kb9wrCHn0Meta?.path ?? "/auth/login/dev",
    meta: dev0kb9wrCHn0Meta || {},
    alias: dev0kb9wrCHn0Meta?.alias || [],
    redirect: dev0kb9wrCHn0Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/login/dev.vue").then(m => m.default || m)
  },
  {
    name: dev0kb9wrCHn0Meta?.name ?? "auth-login-dev___fr",
    path: dev0kb9wrCHn0Meta?.path ?? "/fr/auth/login/dev",
    meta: dev0kb9wrCHn0Meta || {},
    alias: dev0kb9wrCHn0Meta?.alias || [],
    redirect: dev0kb9wrCHn0Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/login/dev.vue").then(m => m.default || m)
  },
  {
    name: proXMqHgZZMd9Meta?.name ?? "auth-login-pro___en",
    path: proXMqHgZZMd9Meta?.path ?? "/auth/login/pro",
    meta: proXMqHgZZMd9Meta || {},
    alias: proXMqHgZZMd9Meta?.alias || [],
    redirect: proXMqHgZZMd9Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/login/pro.vue").then(m => m.default || m)
  },
  {
    name: proXMqHgZZMd9Meta?.name ?? "auth-login-pro___fr",
    path: proXMqHgZZMd9Meta?.path ?? "/fr/auth/login/pro",
    meta: proXMqHgZZMd9Meta || {},
    alias: proXMqHgZZMd9Meta?.alias || [],
    redirect: proXMqHgZZMd9Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/login/pro.vue").then(m => m.default || m)
  },
  {
    name: oauth858D4dwL5HMeta?.name ?? "auth-oauth___en",
    path: oauth858D4dwL5HMeta?.path ?? "/auth/oauth",
    meta: oauth858D4dwL5HMeta || {},
    alias: oauth858D4dwL5HMeta?.alias || [],
    redirect: oauth858D4dwL5HMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/oauth.vue").then(m => m.default || m)
  },
  {
    name: oauth858D4dwL5HMeta?.name ?? "auth-oauth___fr",
    path: oauth858D4dwL5HMeta?.path ?? "/fr/auth/oauth",
    meta: oauth858D4dwL5HMeta || {},
    alias: oauth858D4dwL5HMeta?.alias || [],
    redirect: oauth858D4dwL5HMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/oauth.vue").then(m => m.default || m)
  },
  {
    name: password_45lessGecIuzjLMFMeta?.name ?? "auth-password-less___en",
    path: password_45lessGecIuzjLMFMeta?.path ?? "/auth/password-less",
    meta: password_45lessGecIuzjLMFMeta || {},
    alias: password_45lessGecIuzjLMFMeta?.alias || [],
    redirect: password_45lessGecIuzjLMFMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/password-less.vue").then(m => m.default || m)
  },
  {
    name: password_45lessGecIuzjLMFMeta?.name ?? "auth-password-less___fr",
    path: password_45lessGecIuzjLMFMeta?.path ?? "/fr/auth/password-less",
    meta: password_45lessGecIuzjLMFMeta || {},
    alias: password_45lessGecIuzjLMFMeta?.alias || [],
    redirect: password_45lessGecIuzjLMFMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/password-less.vue").then(m => m.default || m)
  },
  {
    name: devlktZQSRmVRMeta?.name ?? "auth-signup-dev___en",
    path: devlktZQSRmVRMeta?.path ?? "/auth/signup/dev",
    meta: devlktZQSRmVRMeta || {},
    alias: devlktZQSRmVRMeta?.alias || [],
    redirect: devlktZQSRmVRMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/signup/dev.vue").then(m => m.default || m)
  },
  {
    name: devlktZQSRmVRMeta?.name ?? "auth-signup-dev___fr",
    path: devlktZQSRmVRMeta?.path ?? "/fr/auth/signup/dev",
    meta: devlktZQSRmVRMeta || {},
    alias: devlktZQSRmVRMeta?.alias || [],
    redirect: devlktZQSRmVRMeta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/signup/dev.vue").then(m => m.default || m)
  },
  {
    name: devNYk7wMTSA5Meta?.name ?? "auth-verification-dev___en",
    path: devNYk7wMTSA5Meta?.path ?? "/auth/verification/dev",
    meta: devNYk7wMTSA5Meta || {},
    alias: devNYk7wMTSA5Meta?.alias || [],
    redirect: devNYk7wMTSA5Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/verification/dev.vue").then(m => m.default || m)
  },
  {
    name: devNYk7wMTSA5Meta?.name ?? "auth-verification-dev___fr",
    path: devNYk7wMTSA5Meta?.path ?? "/fr/auth/verification/dev",
    meta: devNYk7wMTSA5Meta || {},
    alias: devNYk7wMTSA5Meta?.alias || [],
    redirect: devNYk7wMTSA5Meta?.redirect,
    component: () => import("/workspace/packages/auth/pages/auth/verification/dev.vue").then(m => m.default || m)
  },
  {
    name: competitionTad8X9v6L3Meta?.name ?? "competition___en",
    path: competitionTad8X9v6L3Meta?.path ?? "/competition",
    meta: competitionTad8X9v6L3Meta || {},
    alias: competitionTad8X9v6L3Meta?.alias || [],
    redirect: competitionTad8X9v6L3Meta?.redirect,
    component: () => import("/workspace/pages/competition.vue").then(m => m.default || m)
  },
  {
    name: competitionTad8X9v6L3Meta?.name ?? "competition___fr",
    path: competitionTad8X9v6L3Meta?.path ?? "/fr/competition",
    meta: competitionTad8X9v6L3Meta || {},
    alias: competitionTad8X9v6L3Meta?.alias || [],
    redirect: competitionTad8X9v6L3Meta?.redirect,
    component: () => import("/workspace/pages/competition.vue").then(m => m.default || m)
  },
  {
    name: all_45inclusivePHdUacs3X9Meta?.name ?? "dev-all-inclusive___en",
    path: all_45inclusivePHdUacs3X9Meta?.path ?? "/dev/all-inclusive",
    meta: all_45inclusivePHdUacs3X9Meta || {},
    alias: all_45inclusivePHdUacs3X9Meta?.alias || [],
    redirect: all_45inclusivePHdUacs3X9Meta?.redirect,
    component: () => import("/workspace/pages/dev/all-inclusive.vue").then(m => m.default || m)
  },
  {
    name: all_45inclusivePHdUacs3X9Meta?.name ?? "dev-all-inclusive___fr",
    path: all_45inclusivePHdUacs3X9Meta?.path ?? "/fr/dev/all-inclusive",
    meta: all_45inclusivePHdUacs3X9Meta || {},
    alias: all_45inclusivePHdUacs3X9Meta?.alias || [],
    redirect: all_45inclusivePHdUacs3X9Meta?.redirect,
    component: () => import("/workspace/pages/dev/all-inclusive.vue").then(m => m.default || m)
  },
  {
    name: contactcv06fcFw7pMeta?.name ?? "dev-contact___en",
    path: contactcv06fcFw7pMeta?.path ?? "/dev/contact",
    meta: contactcv06fcFw7pMeta || {},
    alias: contactcv06fcFw7pMeta?.alias || [],
    redirect: contactcv06fcFw7pMeta?.redirect,
    component: () => import("/workspace/pages/dev/contact.vue").then(m => m.default || m)
  },
  {
    name: contactcv06fcFw7pMeta?.name ?? "dev-contact___fr",
    path: contactcv06fcFw7pMeta?.path ?? "/fr/dev/contact",
    meta: contactcv06fcFw7pMeta || {},
    alias: contactcv06fcFw7pMeta?.alias || [],
    redirect: contactcv06fcFw7pMeta?.redirect,
    component: () => import("/workspace/pages/dev/contact.vue").then(m => m.default || m)
  },
  {
    name: indexxaPbEO2OZpMeta?.name ?? "dev___en",
    path: indexxaPbEO2OZpMeta?.path ?? "/dev",
    meta: indexxaPbEO2OZpMeta || {},
    alias: indexxaPbEO2OZpMeta?.alias || [],
    redirect: indexxaPbEO2OZpMeta?.redirect,
    component: () => import("/workspace/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: indexxaPbEO2OZpMeta?.name ?? "dev___fr",
    path: indexxaPbEO2OZpMeta?.path ?? "/fr/dev",
    meta: indexxaPbEO2OZpMeta || {},
    alias: indexxaPbEO2OZpMeta?.alias || [],
    redirect: indexxaPbEO2OZpMeta?.redirect,
    component: () => import("/workspace/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: iso_4582304_452_45assessment5QtE3dkuQBMeta?.name ?? "dev-iso-82304-2-assessment___en",
    path: iso_4582304_452_45assessment5QtE3dkuQBMeta?.path ?? "/dev/iso-82304-2-assessment",
    meta: iso_4582304_452_45assessment5QtE3dkuQBMeta || {},
    alias: iso_4582304_452_45assessment5QtE3dkuQBMeta?.alias || [],
    redirect: iso_4582304_452_45assessment5QtE3dkuQBMeta?.redirect,
    component: () => import("/workspace/pages/dev/iso-82304-2-assessment.vue").then(m => m.default || m)
  },
  {
    name: iso_4582304_452_45assessment5QtE3dkuQBMeta?.name ?? "dev-iso-82304-2-assessment___fr",
    path: iso_4582304_452_45assessment5QtE3dkuQBMeta?.path ?? "/fr/dev/iso-82304-2-assessment",
    meta: iso_4582304_452_45assessment5QtE3dkuQBMeta || {},
    alias: iso_4582304_452_45assessment5QtE3dkuQBMeta?.alias || [],
    redirect: iso_4582304_452_45assessment5QtE3dkuQBMeta?.redirect,
    component: () => import("/workspace/pages/dev/iso-82304-2-assessment.vue").then(m => m.default || m)
  },
  {
    name: pre_45clinical_45assessment2DSj2mjwvyMeta?.name ?? "dev-pre-clinical-assessment___en",
    path: pre_45clinical_45assessment2DSj2mjwvyMeta?.path ?? "/dev/pre-clinical-assessment",
    meta: pre_45clinical_45assessment2DSj2mjwvyMeta || {},
    alias: pre_45clinical_45assessment2DSj2mjwvyMeta?.alias || [],
    redirect: pre_45clinical_45assessment2DSj2mjwvyMeta?.redirect,
    component: () => import("/workspace/pages/dev/pre-clinical-assessment.vue").then(m => m.default || m)
  },
  {
    name: pre_45clinical_45assessment2DSj2mjwvyMeta?.name ?? "dev-pre-clinical-assessment___fr",
    path: pre_45clinical_45assessment2DSj2mjwvyMeta?.path ?? "/fr/dev/pre-clinical-assessment",
    meta: pre_45clinical_45assessment2DSj2mjwvyMeta || {},
    alias: pre_45clinical_45assessment2DSj2mjwvyMeta?.alias || [],
    redirect: pre_45clinical_45assessment2DSj2mjwvyMeta?.redirect,
    component: () => import("/workspace/pages/dev/pre-clinical-assessment.vue").then(m => m.default || m)
  },
  {
    name: trust_45report1xr2pFsE0rMeta?.name ?? "dev-trust-report___en",
    path: trust_45report1xr2pFsE0rMeta?.path ?? "/dev/trust-report",
    meta: trust_45report1xr2pFsE0rMeta || {},
    alias: trust_45report1xr2pFsE0rMeta?.alias || [],
    redirect: trust_45report1xr2pFsE0rMeta?.redirect,
    component: () => import("/workspace/pages/dev/trust-report.vue").then(m => m.default || m)
  },
  {
    name: trust_45report1xr2pFsE0rMeta?.name ?? "dev-trust-report___fr",
    path: trust_45report1xr2pFsE0rMeta?.path ?? "/fr/dev/trust-report",
    meta: trust_45report1xr2pFsE0rMeta || {},
    alias: trust_45report1xr2pFsE0rMeta?.alias || [],
    redirect: trust_45report1xr2pFsE0rMeta?.redirect,
    component: () => import("/workspace/pages/dev/trust-report.vue").then(m => m.default || m)
  },
  {
    name: all_45inclusiveYf2guohUSZMeta?.name ?? "developer-assessment-all-inclusive___en",
    path: all_45inclusiveYf2guohUSZMeta?.path ?? "/developer/assessment/all-inclusive",
    meta: all_45inclusiveYf2guohUSZMeta || {},
    alias: all_45inclusiveYf2guohUSZMeta?.alias || [],
    redirect: all_45inclusiveYf2guohUSZMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/assessment/all-inclusive.vue").then(m => m.default || m)
  },
  {
    name: all_45inclusiveYf2guohUSZMeta?.name ?? "developer-assessment-all-inclusive___fr",
    path: all_45inclusiveYf2guohUSZMeta?.path ?? "/fr/developer/assessment/all-inclusive",
    meta: all_45inclusiveYf2guohUSZMeta || {},
    alias: all_45inclusiveYf2guohUSZMeta?.alias || [],
    redirect: all_45inclusiveYf2guohUSZMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/assessment/all-inclusive.vue").then(m => m.default || m)
  },
  {
    name: iso_4582304_452DmeQcwML4HMeta?.name ?? "developer-assessment-iso-82304-2___en",
    path: iso_4582304_452DmeQcwML4HMeta?.path ?? "/developer/assessment/iso-82304-2",
    meta: iso_4582304_452DmeQcwML4HMeta || {},
    alias: iso_4582304_452DmeQcwML4HMeta?.alias || [],
    redirect: iso_4582304_452DmeQcwML4HMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/assessment/iso-82304-2.vue").then(m => m.default || m)
  },
  {
    name: iso_4582304_452DmeQcwML4HMeta?.name ?? "developer-assessment-iso-82304-2___fr",
    path: iso_4582304_452DmeQcwML4HMeta?.path ?? "/fr/developer/assessment/iso-82304-2",
    meta: iso_4582304_452DmeQcwML4HMeta || {},
    alias: iso_4582304_452DmeQcwML4HMeta?.alias || [],
    redirect: iso_4582304_452DmeQcwML4HMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/assessment/iso-82304-2.vue").then(m => m.default || m)
  },
  {
    name: pre_45clinicalozHhrHp7i4Meta?.name ?? "developer-assessment-pre-clinical___en",
    path: pre_45clinicalozHhrHp7i4Meta?.path ?? "/developer/assessment/pre-clinical",
    meta: pre_45clinicalozHhrHp7i4Meta || {},
    alias: pre_45clinicalozHhrHp7i4Meta?.alias || [],
    redirect: pre_45clinicalozHhrHp7i4Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/assessment/pre-clinical.vue").then(m => m.default || m)
  },
  {
    name: pre_45clinicalozHhrHp7i4Meta?.name ?? "developer-assessment-pre-clinical___fr",
    path: pre_45clinicalozHhrHp7i4Meta?.path ?? "/fr/developer/assessment/pre-clinical",
    meta: pre_45clinicalozHhrHp7i4Meta || {},
    alias: pre_45clinicalozHhrHp7i4Meta?.alias || [],
    redirect: pre_45clinicalozHhrHp7i4Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/assessment/pre-clinical.vue").then(m => m.default || m)
  },
  {
    name: business_45model8BjPOxII9pMeta?.name ?? "developer-business-model___en",
    path: business_45model8BjPOxII9pMeta?.path ?? "/developer/business-model",
    meta: business_45model8BjPOxII9pMeta || {},
    alias: business_45model8BjPOxII9pMeta?.alias || [],
    redirect: business_45model8BjPOxII9pMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/business-model.vue").then(m => m.default || m)
  },
  {
    name: business_45model8BjPOxII9pMeta?.name ?? "developer-business-model___fr",
    path: business_45model8BjPOxII9pMeta?.path ?? "/fr/developer/business-model",
    meta: business_45model8BjPOxII9pMeta || {},
    alias: business_45model8BjPOxII9pMeta?.alias || [],
    redirect: business_45model8BjPOxII9pMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/business-model.vue").then(m => m.default || m)
  },
  {
    name: claim4F7Y2E0dC1Meta?.name ?? "developer-claim___en",
    path: claim4F7Y2E0dC1Meta?.path ?? "/developer/claim",
    meta: claim4F7Y2E0dC1Meta || {},
    alias: claim4F7Y2E0dC1Meta?.alias || [],
    redirect: claim4F7Y2E0dC1Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/claim.vue").then(m => m.default || m)
  },
  {
    name: claim4F7Y2E0dC1Meta?.name ?? "developer-claim___fr",
    path: claim4F7Y2E0dC1Meta?.path ?? "/fr/developer/claim",
    meta: claim4F7Y2E0dC1Meta || {},
    alias: claim4F7Y2E0dC1Meta?.alias || [],
    redirect: claim4F7Y2E0dC1Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/claim.vue").then(m => m.default || m)
  },
  {
    name: first_45claimsiWzBnylp1Meta?.name ?? "developer-first-claim___en",
    path: first_45claimsiWzBnylp1Meta?.path ?? "/developer/first-claim",
    meta: first_45claimsiWzBnylp1Meta || {},
    alias: first_45claimsiWzBnylp1Meta?.alias || [],
    redirect: first_45claimsiWzBnylp1Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/first-claim.vue").then(m => m.default || m)
  },
  {
    name: first_45claimsiWzBnylp1Meta?.name ?? "developer-first-claim___fr",
    path: first_45claimsiWzBnylp1Meta?.path ?? "/fr/developer/first-claim",
    meta: first_45claimsiWzBnylp1Meta || {},
    alias: first_45claimsiWzBnylp1Meta?.alias || [],
    redirect: first_45claimsiWzBnylp1Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/first-claim.vue").then(m => m.default || m)
  },
  {
    name: indexVdgCBC7u65Meta?.name ?? "developer___en",
    path: indexVdgCBC7u65Meta?.path ?? "/developer",
    meta: indexVdgCBC7u65Meta || {},
    alias: indexVdgCBC7u65Meta?.alias || [],
    redirect: indexVdgCBC7u65Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/index.vue").then(m => m.default || m)
  },
  {
    name: indexVdgCBC7u65Meta?.name ?? "developer___fr",
    path: indexVdgCBC7u65Meta?.path ?? "/fr/developer",
    meta: indexVdgCBC7u65Meta || {},
    alias: indexVdgCBC7u65Meta?.alias || [],
    redirect: indexVdgCBC7u65Meta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/index.vue").then(m => m.default || m)
  },
  {
    name: profileJ5ccJHz4nTMeta?.name ?? "developer-profile___en",
    path: profileJ5ccJHz4nTMeta?.path ?? "/developer/profile",
    meta: profileJ5ccJHz4nTMeta || {},
    alias: profileJ5ccJHz4nTMeta?.alias || [],
    redirect: profileJ5ccJHz4nTMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/profile.vue").then(m => m.default || m)
  },
  {
    name: profileJ5ccJHz4nTMeta?.name ?? "developer-profile___fr",
    path: profileJ5ccJHz4nTMeta?.path ?? "/fr/developer/profile",
    meta: profileJ5ccJHz4nTMeta || {},
    alias: profileJ5ccJHz4nTMeta?.alias || [],
    redirect: profileJ5ccJHz4nTMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/profile.vue").then(m => m.default || m)
  },
  {
    name: trust_45reportdhxisW7BnJMeta?.name ?? "developer-trust-report___en",
    path: trust_45reportdhxisW7BnJMeta?.path ?? "/developer/trust-report",
    meta: trust_45reportdhxisW7BnJMeta || {},
    alias: trust_45reportdhxisW7BnJMeta?.alias || [],
    redirect: trust_45reportdhxisW7BnJMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/trust-report.vue").then(m => m.default || m)
  },
  {
    name: trust_45reportdhxisW7BnJMeta?.name ?? "developer-trust-report___fr",
    path: trust_45reportdhxisW7BnJMeta?.path ?? "/fr/developer/trust-report",
    meta: trust_45reportdhxisW7BnJMeta || {},
    alias: trust_45reportdhxisW7BnJMeta?.alias || [],
    redirect: trust_45reportdhxisW7BnJMeta?.redirect,
    component: () => import("/workspace/packages/developer/pages/developer/trust-report.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index___en",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index___fr",
    path: index9S03gOyIVcMeta?.path ?? "/fr",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.name ?? "investors-questions-to-de-risk-your-next-digital-health-investment___en",
    path: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.path ?? "/investors/questions-to-de-risk-your-next-digital-health-investment",
    meta: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta || {},
    alias: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.alias || [],
    redirect: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.redirect,
    component: () => import("/workspace/pages/investors/questions-to-de-risk-your-next-digital-health-investment.vue").then(m => m.default || m)
  },
  {
    name: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.name ?? "investors-questions-to-de-risk-your-next-digital-health-investment___fr",
    path: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.path ?? "/fr/investors/questions-to-de-risk-your-next-digital-health-investment",
    meta: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta || {},
    alias: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.alias || [],
    redirect: questions_45to_45de_45risk_45your_45next_45digital_45health_45investmentUdeKKWuNLZMeta?.redirect,
    component: () => import("/workspace/pages/investors/questions-to-de-risk-your-next-digital-health-investment.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyJuUuuPjb3dMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyJuUuuPjb3dMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyJuUuuPjb3dMeta || {},
    alias: privacy_45policyJuUuuPjb3dMeta?.alias || [],
    redirect: privacy_45policyJuUuuPjb3dMeta?.redirect,
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyJuUuuPjb3dMeta?.name ?? "privacy-policy___fr",
    path: privacy_45policyJuUuuPjb3dMeta?.path ?? "/fr/privacy-policy",
    meta: privacy_45policyJuUuuPjb3dMeta || {},
    alias: privacy_45policyJuUuuPjb3dMeta?.alias || [],
    redirect: privacy_45policyJuUuuPjb3dMeta?.redirect,
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexiZvgslzpztMeta?.name ?? "pro___en",
    path: indexiZvgslzpztMeta?.path ?? "/pro",
    meta: indexiZvgslzpztMeta || {},
    alias: indexiZvgslzpztMeta?.alias || [],
    redirect: indexiZvgslzpztMeta?.redirect,
    component: () => import("/workspace/pages/pro/index.vue").then(m => m.default || m)
  },
  {
    name: indexiZvgslzpztMeta?.name ?? "pro___fr",
    path: indexiZvgslzpztMeta?.path ?? "/fr/pro",
    meta: indexiZvgslzpztMeta || {},
    alias: indexiZvgslzpztMeta?.alias || [],
    redirect: indexiZvgslzpztMeta?.redirect,
    component: () => import("/workspace/pages/pro/index.vue").then(m => m.default || m)
  },
  {
    name: _91libraryId_93cSSZuaDSOtMeta?.name ?? "pro-libraries-libraryId___en",
    path: _91libraryId_93cSSZuaDSOtMeta?.path ?? "/pro/libraries/:libraryId()",
    meta: _91libraryId_93cSSZuaDSOtMeta || {},
    alias: _91libraryId_93cSSZuaDSOtMeta?.alias || [],
    redirect: _91libraryId_93cSSZuaDSOtMeta?.redirect,
    component: () => import("/workspace/pages/pro/libraries/[libraryId].vue").then(m => m.default || m)
  },
  {
    name: _91libraryId_93cSSZuaDSOtMeta?.name ?? "pro-libraries-libraryId___fr",
    path: _91libraryId_93cSSZuaDSOtMeta?.path ?? "/fr/pro/libraries/:libraryId()",
    meta: _91libraryId_93cSSZuaDSOtMeta || {},
    alias: _91libraryId_93cSSZuaDSOtMeta?.alias || [],
    redirect: _91libraryId_93cSSZuaDSOtMeta?.redirect,
    component: () => import("/workspace/pages/pro/libraries/[libraryId].vue").then(m => m.default || m)
  },
  {
    name: get_45libraryqC6iwwjphhMeta?.name ?? "pro-libraries-get-library___en",
    path: get_45libraryqC6iwwjphhMeta?.path ?? "/pro/libraries/get-library",
    meta: get_45libraryqC6iwwjphhMeta || {},
    alias: get_45libraryqC6iwwjphhMeta?.alias || [],
    redirect: get_45libraryqC6iwwjphhMeta?.redirect,
    component: () => import("/workspace/pages/pro/libraries/get-library.vue").then(m => m.default || m)
  },
  {
    name: get_45libraryqC6iwwjphhMeta?.name ?? "pro-libraries-get-library___fr",
    path: get_45libraryqC6iwwjphhMeta?.path ?? "/fr/pro/libraries/get-library",
    meta: get_45libraryqC6iwwjphhMeta || {},
    alias: get_45libraryqC6iwwjphhMeta?.alias || [],
    redirect: get_45libraryqC6iwwjphhMeta?.redirect,
    component: () => import("/workspace/pages/pro/libraries/get-library.vue").then(m => m.default || m)
  },
  {
    name: indexIWUrDsg8fTMeta?.name ?? "pro-libraries___en",
    path: indexIWUrDsg8fTMeta?.path ?? "/pro/libraries",
    meta: indexIWUrDsg8fTMeta || {},
    alias: indexIWUrDsg8fTMeta?.alias || [],
    redirect: indexIWUrDsg8fTMeta?.redirect,
    component: () => import("/workspace/pages/pro/libraries/index.vue").then(m => m.default || m)
  },
  {
    name: indexIWUrDsg8fTMeta?.name ?? "pro-libraries___fr",
    path: indexIWUrDsg8fTMeta?.path ?? "/fr/pro/libraries",
    meta: indexIWUrDsg8fTMeta || {},
    alias: indexIWUrDsg8fTMeta?.alias || [],
    redirect: indexIWUrDsg8fTMeta?.redirect,
    component: () => import("/workspace/pages/pro/libraries/index.vue").then(m => m.default || m)
  },
  {
    name: quality_45scoresuTAPXP0d1KMeta?.name ?? "quality-scores___en",
    path: quality_45scoresuTAPXP0d1KMeta?.path ?? "/quality-scores",
    meta: quality_45scoresuTAPXP0d1KMeta || {},
    alias: quality_45scoresuTAPXP0d1KMeta?.alias || [],
    redirect: quality_45scoresuTAPXP0d1KMeta?.redirect,
    component: () => import("/workspace/pages/quality-scores.vue").then(m => m.default || m)
  },
  {
    name: quality_45scoresuTAPXP0d1KMeta?.name ?? "quality-scores___fr",
    path: quality_45scoresuTAPXP0d1KMeta?.path ?? "/fr/quality-scores",
    meta: quality_45scoresuTAPXP0d1KMeta || {},
    alias: quality_45scoresuTAPXP0d1KMeta?.alias || [],
    redirect: quality_45scoresuTAPXP0d1KMeta?.redirect,
    component: () => import("/workspace/pages/quality-scores.vue").then(m => m.default || m)
  },
  {
    name: relevanceSh4qMazwVSMeta?.name ?? "relevance___en",
    path: relevanceSh4qMazwVSMeta?.path ?? "/relevance",
    meta: relevanceSh4qMazwVSMeta || {},
    alias: relevanceSh4qMazwVSMeta?.alias || [],
    redirect: relevanceSh4qMazwVSMeta?.redirect,
    component: () => import("/workspace/pages/relevance.vue").then(m => m.default || m)
  },
  {
    name: relevanceSh4qMazwVSMeta?.name ?? "relevance___fr",
    path: relevanceSh4qMazwVSMeta?.path ?? "/fr/relevance",
    meta: relevanceSh4qMazwVSMeta || {},
    alias: relevanceSh4qMazwVSMeta?.alias || [],
    redirect: relevanceSh4qMazwVSMeta?.redirect,
    component: () => import("/workspace/pages/relevance.vue").then(m => m.default || m)
  },
  {
    name: request_45reviewVUAHAzK7TRMeta?.name ?? "request-review___en",
    path: request_45reviewVUAHAzK7TRMeta?.path ?? "/request-review",
    meta: request_45reviewVUAHAzK7TRMeta || {},
    alias: request_45reviewVUAHAzK7TRMeta?.alias || [],
    redirect: request_45reviewVUAHAzK7TRMeta?.redirect,
    component: () => import("/workspace/pages/request-review.vue").then(m => m.default || m)
  },
  {
    name: request_45reviewVUAHAzK7TRMeta?.name ?? "request-review___fr",
    path: request_45reviewVUAHAzK7TRMeta?.path ?? "/fr/request-review",
    meta: request_45reviewVUAHAzK7TRMeta || {},
    alias: request_45reviewVUAHAzK7TRMeta?.alias || [],
    redirect: request_45reviewVUAHAzK7TRMeta?.redirect,
    component: () => import("/workspace/pages/request-review.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VlgNx52LwyMeta?.name ?? "resources-articles-slug___en",
    path: _91slug_93VlgNx52LwyMeta?.path ?? "/resources/articles/:slug()",
    meta: _91slug_93VlgNx52LwyMeta || {},
    alias: _91slug_93VlgNx52LwyMeta?.alias || [],
    redirect: _91slug_93VlgNx52LwyMeta?.redirect,
    component: () => import("/workspace/pages/resources/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VlgNx52LwyMeta?.name ?? "resources-articles-slug___fr",
    path: _91slug_93VlgNx52LwyMeta?.path ?? "/fr/resources/articles/:slug()",
    meta: _91slug_93VlgNx52LwyMeta || {},
    alias: _91slug_93VlgNx52LwyMeta?.alias || [],
    redirect: _91slug_93VlgNx52LwyMeta?.redirect,
    component: () => import("/workspace/pages/resources/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93NifOgurSaXMeta?.name ?? "resources-articles-conditions-condition___en",
    path: _91condition_93NifOgurSaXMeta?.path ?? "/resources/articles/conditions/:condition()",
    meta: _91condition_93NifOgurSaXMeta || {},
    alias: _91condition_93NifOgurSaXMeta?.alias || [],
    redirect: _91condition_93NifOgurSaXMeta?.redirect,
    component: () => import("/workspace/pages/resources/articles/conditions/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93NifOgurSaXMeta?.name ?? "resources-articles-conditions-condition___fr",
    path: _91condition_93NifOgurSaXMeta?.path ?? "/fr/resources/articles/conditions/:condition()",
    meta: _91condition_93NifOgurSaXMeta || {},
    alias: _91condition_93NifOgurSaXMeta?.alias || [],
    redirect: _91condition_93NifOgurSaXMeta?.redirect,
    component: () => import("/workspace/pages/resources/articles/conditions/[condition].vue").then(m => m.default || m)
  },
  {
    name: indexE83Z3kIzhYMeta?.name ?? "resources-articles___en",
    path: indexE83Z3kIzhYMeta?.path ?? "/resources/articles",
    meta: indexE83Z3kIzhYMeta || {},
    alias: indexE83Z3kIzhYMeta?.alias || [],
    redirect: indexE83Z3kIzhYMeta?.redirect,
    component: () => import("/workspace/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexE83Z3kIzhYMeta?.name ?? "resources-articles___fr",
    path: indexE83Z3kIzhYMeta?.path ?? "/fr/resources/articles",
    meta: indexE83Z3kIzhYMeta || {},
    alias: indexE83Z3kIzhYMeta?.alias || [],
    redirect: indexE83Z3kIzhYMeta?.redirect,
    component: () => import("/workspace/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91condition_93NFNcrWrvLYMeta?.name ?? "resources-collections-medication-adherence-condition___en",
    path: _91condition_93NFNcrWrvLYMeta?.path ?? "/resources/collections/medication-adherence/:condition()",
    meta: _91condition_93NFNcrWrvLYMeta || {},
    alias: _91condition_93NFNcrWrvLYMeta?.alias || [],
    redirect: _91condition_93NFNcrWrvLYMeta?.redirect,
    component: () => import("/workspace/pages/resources/collections/medication-adherence/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93NFNcrWrvLYMeta?.name ?? "resources-collections-medication-adherence-condition___fr",
    path: _91condition_93NFNcrWrvLYMeta?.path ?? "/fr/resources/collections/medication-adherence/:condition()",
    meta: _91condition_93NFNcrWrvLYMeta || {},
    alias: _91condition_93NFNcrWrvLYMeta?.alias || [],
    redirect: _91condition_93NFNcrWrvLYMeta?.redirect,
    component: () => import("/workspace/pages/resources/collections/medication-adherence/[condition].vue").then(m => m.default || m)
  },
  {
    name: index2qEdVOhH2PMeta?.name ?? "resources-collections-medication-adherence___en",
    path: index2qEdVOhH2PMeta?.path ?? "/resources/collections/medication-adherence",
    meta: index2qEdVOhH2PMeta || {},
    alias: index2qEdVOhH2PMeta?.alias || [],
    redirect: index2qEdVOhH2PMeta?.redirect,
    component: () => import("/workspace/pages/resources/collections/medication-adherence/index.vue").then(m => m.default || m)
  },
  {
    name: index2qEdVOhH2PMeta?.name ?? "resources-collections-medication-adherence___fr",
    path: index2qEdVOhH2PMeta?.path ?? "/fr/resources/collections/medication-adherence",
    meta: index2qEdVOhH2PMeta || {},
    alias: index2qEdVOhH2PMeta?.alias || [],
    redirect: index2qEdVOhH2PMeta?.redirect,
    component: () => import("/workspace/pages/resources/collections/medication-adherence/index.vue").then(m => m.default || m)
  },
  {
    name: _91condition_93neppnJFc0BMeta?.name ?? "resources-collections-quit-smoking-condition___en",
    path: _91condition_93neppnJFc0BMeta?.path ?? "/resources/collections/quit-smoking/:condition()",
    meta: _91condition_93neppnJFc0BMeta || {},
    alias: _91condition_93neppnJFc0BMeta?.alias || [],
    redirect: _91condition_93neppnJFc0BMeta?.redirect,
    component: () => import("/workspace/pages/resources/collections/quit-smoking/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93neppnJFc0BMeta?.name ?? "resources-collections-quit-smoking-condition___fr",
    path: _91condition_93neppnJFc0BMeta?.path ?? "/fr/resources/collections/quit-smoking/:condition()",
    meta: _91condition_93neppnJFc0BMeta || {},
    alias: _91condition_93neppnJFc0BMeta?.alias || [],
    redirect: _91condition_93neppnJFc0BMeta?.redirect,
    component: () => import("/workspace/pages/resources/collections/quit-smoking/[condition].vue").then(m => m.default || m)
  },
  {
    name: indexsnk7Ndn6r9Meta?.name ?? "resources-collections-quit-smoking___en",
    path: indexsnk7Ndn6r9Meta?.path ?? "/resources/collections/quit-smoking",
    meta: indexsnk7Ndn6r9Meta || {},
    alias: indexsnk7Ndn6r9Meta?.alias || [],
    redirect: indexsnk7Ndn6r9Meta?.redirect,
    component: () => import("/workspace/pages/resources/collections/quit-smoking/index.vue").then(m => m.default || m)
  },
  {
    name: indexsnk7Ndn6r9Meta?.name ?? "resources-collections-quit-smoking___fr",
    path: indexsnk7Ndn6r9Meta?.path ?? "/fr/resources/collections/quit-smoking",
    meta: indexsnk7Ndn6r9Meta || {},
    alias: indexsnk7Ndn6r9Meta?.alias || [],
    redirect: indexsnk7Ndn6r9Meta?.redirect,
    component: () => import("/workspace/pages/resources/collections/quit-smoking/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.name ?? "terms-and-conditions-2021-10-25___en",
    path: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.path ?? "/terms-and-conditions-2021-10-25",
    meta: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta || {},
    alias: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.alias || [],
    redirect: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.redirect,
    component: () => import("/workspace/pages/terms-and-conditions-2021-10-25.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.name ?? "terms-and-conditions-2021-10-25___fr",
    path: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.path ?? "/fr/terms-and-conditions-2021-10-25",
    meta: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta || {},
    alias: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.alias || [],
    redirect: terms_45and_45conditions_452021_4510_4525lutmRnOZ7WMeta?.redirect,
    component: () => import("/workspace/pages/terms-and-conditions-2021-10-25.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsD3pGvh9rLRMeta?.name ?? "terms-and-conditions___en",
    path: terms_45and_45conditionsD3pGvh9rLRMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsD3pGvh9rLRMeta || {},
    alias: terms_45and_45conditionsD3pGvh9rLRMeta?.alias || [],
    redirect: terms_45and_45conditionsD3pGvh9rLRMeta?.redirect,
    component: () => import("/workspace/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsD3pGvh9rLRMeta?.name ?? "terms-and-conditions___fr",
    path: terms_45and_45conditionsD3pGvh9rLRMeta?.path ?? "/fr/terms-and-conditions",
    meta: terms_45and_45conditionsD3pGvh9rLRMeta || {},
    alias: terms_45and_45conditionsD3pGvh9rLRMeta?.alias || [],
    redirect: terms_45and_45conditionsD3pGvh9rLRMeta?.redirect,
    component: () => import("/workspace/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46code_930MHcggYpvxMeta?.name ?? "u-code___en",
    path: _91_46_46_46code_930MHcggYpvxMeta?.path ?? "/u/:code(.*)*",
    meta: _91_46_46_46code_930MHcggYpvxMeta || {},
    alias: _91_46_46_46code_930MHcggYpvxMeta?.alias || [],
    redirect: _91_46_46_46code_930MHcggYpvxMeta?.redirect,
    component: () => import("/workspace/pages/u/[...code].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46code_930MHcggYpvxMeta?.name ?? "u-code___fr",
    path: _91_46_46_46code_930MHcggYpvxMeta?.path ?? "/fr/u/:code(.*)*",
    meta: _91_46_46_46code_930MHcggYpvxMeta || {},
    alias: _91_46_46_46code_930MHcggYpvxMeta?.alias || [],
    redirect: _91_46_46_46code_930MHcggYpvxMeta?.redirect,
    component: () => import("/workspace/pages/u/[...code].vue").then(m => m.default || m)
  },
  {
    name: _91locale_93_45_91id_935Pvkxs9hVcMeta?.name ?? "u-redirect-locale-id___en",
    path: _91locale_93_45_91id_935Pvkxs9hVcMeta?.path ?? "/u/redirect/:locale()-:id()",
    meta: _91locale_93_45_91id_935Pvkxs9hVcMeta || {},
    alias: _91locale_93_45_91id_935Pvkxs9hVcMeta?.alias || [],
    redirect: _91locale_93_45_91id_935Pvkxs9hVcMeta?.redirect,
    component: () => import("/workspace/pages/u/redirect/[locale]-[id].vue").then(m => m.default || m)
  },
  {
    name: _91locale_93_45_91id_935Pvkxs9hVcMeta?.name ?? "u-redirect-locale-id___fr",
    path: _91locale_93_45_91id_935Pvkxs9hVcMeta?.path ?? "/fr/u/redirect/:locale()-:id()",
    meta: _91locale_93_45_91id_935Pvkxs9hVcMeta || {},
    alias: _91locale_93_45_91id_935Pvkxs9hVcMeta?.alias || [],
    redirect: _91locale_93_45_91id_935Pvkxs9hVcMeta?.redirect,
    component: () => import("/workspace/pages/u/redirect/[locale]-[id].vue").then(m => m.default || m)
  }
]