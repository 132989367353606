export function useToken() {
  return useCookie('token', {
    maxAge: 60 * 60 * 24 * 7,
  })
}

export function useRefreshToken() {
  return useCookie('refresh-token', {
    maxAge: 60 * 60 * 24 * 14,
  })
}

export function useProfile() {
  return useState<AppGuide.Auth.Profile | null>('profile', () => null)
}

export function useProviders() {
  const config = useRuntimeConfig()

  const providers = useState('providers', () => ({
    pending: {
      en: false,
      fr: false,
    },

    google: {
      title: 'Google',
      dev: {
        en: '',
        fr: '',
      },
    },
    microsoft: {
      title: 'Microsoft',
      dev: {
        en: '',
        fr: '',
      },
    },
  }))

  async function getProviders(language: AppGuide.Locales) {
    if (providers.value.pending[language])
      return

    if (providers.value.google.dev[language] && providers.value.microsoft.dev[language])
      return

    providers.value.pending[language] = true

    try {
      const response = await $fetch<AppGuide.Auth.Providers>('/api/oauth-providers', {
        method: 'GET',
        query: {
          type: 'dev',
          language,
          endpoint: config.public.providerRedirect,
          // endpoint: 'http://localhost:3000',
          // endpoint: 'https://staging.appguide.ca',
          // endpoint: 'https://appguide.ca',
        },
      })

      providers.value.google.dev[language] = response.google
      providers.value.microsoft.dev[language] = response.microsoft
    }
    catch (err) {
      // TODO use toast to display error
      console.error(err)
    }
    finally {
      providers.value.pending[language] = false
    }
  }

  return {
    providers,
    getProviders,
  }
}

export const passwordRules = zod.string()
  .min(8, 'min length error')
  .regex(/(?=.*[!@#$%^&*_0-9]).*$/, 'special char error')
  .regex(/(?=.*[A-Z]).*$/, 'uppercase error')

export function useInitAuth() {
  const done = useState('init-auth', () => false)
  const profile = useProfile()
  const token = useToken()
  const refreshToken = useRefreshToken()

  return async (refresh?: boolean) => {
    if (done.value && !refresh)
      return
    if (!refreshToken.value)
      return

    const response = await api.auth.refresh({ refreshToken: refreshToken.value })

    if (response.statusCode !== 200) {
      token.value = null
      refreshToken.value = null
    }
    else {
      const data = response.data
      token.value = data.access_token
      refreshToken.value = data.refresh_token
      profile.value = data
    }

    done.value = true
  }
}
